<template>
    <div>
        <div class="main-container">
            <KidHeader :profileId="profileId" :serialNumber="serialNumber" />
            <div class="profile-section mb-2">
                <div class="pl-2">
                    <span>
                        <a class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/kids-device', query:{ profileId: this.profileId }})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Home</b></a>
                    </span>
                </div>
            </div>
            <div class="profile-section pt-2">
                <div class="pt-3 m-2" :style="{ 'padding-right': '2px'}">
                    <div class="input-group border">
                        <div class="input-group-prepend">
                            <div class="input-group-text" :style="{ 'background-color': 'white', 'border-left': 'unset','border-top': 'unset', 'border-bottom': 'unset' }" id="btnGroupAddon">
                                <a class="text-black" href="javascript:void(0)" @click="previousDate()">
                                    <!--<i class="fas fa-chevron-circle-left"></i>-->
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="24" width="24" height="24" rx="12" transform="rotate(-90 0 24)" fill="#F2F2F2"/>
                                        <path d="M13.6667 15.3333L10.4003 12.157C10.311 12.0703 10.311 11.9296 10.4003 11.8429L13.6667 8.66663" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </a>
                            </div>
                        </div>
                        <div class="form-control text-center" :style="{ 'border': 'unset', 'font-weight': 'bold' }">{{ this.formattedDate }}</div>
                        <div class="input-group-prepend">
                            <div class="input-group-text" :style="{ 'background-color': 'white', 'border-left': 'unset','border-top': 'unset', 'border-bottom': 'unset' }" id="btnGroupAddon">
                                <a class="text-black" href="javascript:void(0)" @click="nextDate()">
                                    <!--<i class="fas fa-chevron-circle-right"></i>-->
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#F2F2F2"/>
                                        <path d="M10.3333 8.66671L13.5997 11.843C13.689 11.9297 13.689 12.0704 13.5997 12.1571L10.3333 15.3334" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="input-group mb-3 has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control" @keyup.enter="getBrowserLogs()" placeholder="Search" v-model="urlText">
                        </div>
                    </div>
                </div>
                <div class="history-item m-2" v-for="log in browserLogsData" :key="log._id">
                    <div>
                        <a :href="log.url" target="_blank">{{ log.url }}</a><br>
                        <small>{{ log.created_at }}</small>
                    </div>
                    <a href="javascript:void(0)" v-if="log.isBlocked == true" @click="updateLogUrlStatus(log._id, false)"><i class="fas fa-lock lock-icon text-danger"></i></a>
                    <a href="javascript:void(0)" v-else @click="updateLogUrlStatus(log._id, true)"><i class="fas fa-unlock lock-icon text-primary"></i></a>
                </div>
                <p v-if="browserLogsData.length == 0">No data found.</p>
            </div>
            <KidFooter />
        </div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import KidFooter from '@/components/KidFooter.vue'
import KidsService from "../../services/KidsService";
let options = { weekday: 'short', month: 'long', day: 'numeric' };

export default {
  name: 'Browser Logs',
  components: {
    KidHeader,
    KidFooter
  },
  data() {
    return {
        isDisabled: false,
        profileId: this.$route.query.profileId,
        serialNumber: this.$route.query.serialNumber,
        formattedDate: new Date().toLocaleDateString("en-US", options),
        currentDate: new Date(),
        filterType: "Day",
        browserLogsData: [],
        urlText: "",
    }
  },
  methods: {
    previousDate() {
        if (this.filterType == "Day") {
            let day = this.currentDate.getTime() - (1 * 24 * 60 * 60 * 1000);
            this.currentDate.setTime(day);
            this.formattedDate = this.currentDate.toLocaleDateString("en-US", options);
        } else {
            this.formattedDate = "Previous Week"
            this.weekType = "Previous";
        }
        this.getBrowserLogs()
    },
    nextDate() {
        if (this.filterType == "Day") {
            let day = this.currentDate.getTime() + (1 * 24 * 60 * 60 * 1000);
            this.currentDate.setTime(day);
            this.formattedDate = this.currentDate.toLocaleDateString("en-US", options)
        } else {
            this.formattedDate = "This Week",
            this.weekType = "Current";    
        }
        this.getBrowserLogs()
    },
    async getBrowserLogs() {
        let data = { currentDate: this.currentDate, serialNumber: this.serialNumber, urlText: this.urlText };
        await KidsService.getBrowserLogs(data)
        .then((response) => {
          this.browserLogsData = response.data.data;
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async updateLogUrlStatus(id, isBlocked) {
        let data = { id: id, isBlocked: isBlocked };
        await KidsService.updateLogUrlStatus(data)
        .then((response) => {
          this.getBrowserLogs();
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.getBrowserLogs();
  },
}
</script>