<template>
    <div>
        <div class="main-container">
            <KidHeader :profileId="profileId" :serialNumber="serialNumber" />
            <div class="profile-section mb-2">
                <div class="pl-2">
                    <span>
                        <a class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/kids-device', query:{ profileId: this.profileId }})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Home</b></a>
                    </span>
                </div>
            </div>
            <div class="profile-section pt-2">
                 <div class="transactions-container">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h6 class="mb-0">Transactions History</h6>
                    </div>

                    <!-- Transaction Items -->
                    <div class="transaction-item" v-for="payment in paymentHistoryData" :key="payment._id">
                        <div class="transaction-icon"><img :src="payment.imageUrl" height="30px" width="30px" /></div>
                        <div class="transaction-details">
                            <div class="transaction-title">{{ payment.productName }}</div>
                            <small class="transaction-title" v-if="payment.subscriptionCode">
                              Subscription Code: {{ payment.subscriptionCode }} 
                              <span class="badge badge-danger" v-if="payment.subscriptionCodeUsed == true">Used</span>
                              <span class="badge badge-danger ml-1" v-if="new Date(payment.expiryAt) < today">Expired</span>
                            </small>
                            <small v-if="payment.profileName"><br v-if="payment.subscriptionCode"><b>Device Name:</b>  {{ payment.profileName }} ( {{ payment.serialNumber }} ) </small>
                            <div class="transaction-time" v-if="payment.expiryAt">Expiry At: {{ HumanReadableDateFormat(payment.expiryAt, true) }}</div>
                        </div>
                        <div class="text-right">
                            <div class="transaction-amount">£{{ payment.priceDetails.unit_amount / 100 }}</div>
                            <span class="status status-confirmed" v-if="payment.status != 'Fail'">{{ payment.status }}</span>
                            <span class="status status-pending" v-else>{{ payment.status }}</span>
                        </div>
                    </div>
                 </div>    
            </div>
            <KidFooter />
        </div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import KidFooter from '@/components/KidFooter.vue'
import KidsService from "../../services/KidsService";

export default {
  name: 'Payment History',
  components: {
    KidHeader,
    KidFooter
  },
  data() {
    return {
        profileId: this.$route.query.profileId,
        paymentHistoryData: [],
        today: new Date(),
    }
  },
  methods: {
    async getPaymentHistory() {
      await KidsService.getPaymentHistory()
        .then((response) => {
          this.paymentHistoryData = response.data.data;
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    HumanReadableDateFormat(date, onlyDate = false) {
            const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
            let dt = new Date(date);
            let day = ("0" + dt.getDate()).slice(-2);
            let month = dt.getMonth();
            let year = dt.getFullYear();
            let hour = ("0" + dt.getHours()).slice(-2);
            let minute = ("0" + dt.getMinutes()).slice(-2);
            let seconds = ("0" + dt.getSeconds()).slice(-2);
            if (onlyDate == true) {
                return day + " " + monthNames[month] + " " + year;
            } else {
                return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
            }
      },
  },
  beforeMount() {
    this.getPaymentHistory();
  },
}
</script>