<template>
    <div>
        <div class="main-container">
            <KidHeader :profileId="profileId" :serialNumber="serialNumber" />
            <div class="profile-section mb-2">
                <div class="pl-2">
                    <span>
                        <a class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/kids-device', query:{ profileId: this.profileId }})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Home</b></a>
                    </span>
                </div>
            </div>
            <div class="profile-section pt-2">
                 <form class="m-4">
                    <div class="input-group mb-3">
                        <input type="password" v-model="currentPassword" class="form-control password-input"  placeholder="Enter current password">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                            <i class="fa fa-eye-slash" aria-hidden="true" v-if="showPassword == false" @click="togglePasswordDisplay(true)"></i>
                            <i class="fa fa-eye" aria-hidden="true" v-if="showPassword == true" @click="togglePasswordDisplay(false)"></i>
                            </span>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" v-model="newPassword" class="form-control password-input"  placeholder="Enter new password">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                            <i class="fa fa-eye-slash" aria-hidden="true" v-if="showPassword == false" @click="togglePasswordDisplay(true)"></i>
                            <i class="fa fa-eye" aria-hidden="true" v-if="showPassword == true" @click="togglePasswordDisplay(false)"></i>
                            </span>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" v-model="confirmNewPassword" class="form-control password-input" placeholder="Confirm new password">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                            <i class="fa fa-eye-slash" aria-hidden="true" v-if="showPassword == false" @click="togglePasswordDisplay(true)"></i>
                            <i class="fa fa-eye" aria-hidden="true" v-if="showPassword == true" @click="togglePasswordDisplay(false)"></i>
                            </span>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <button class="btn btn-primary login-btn" :disabled="isDisabled" @click="changePassword()">Update Password
                            <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                        </button>
                    </div>
                 </form>
            </div>
            <KidFooter />
        </div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import KidFooter from '@/components/KidFooter.vue'
import KidsService from "../../services/KidsService";

export default {
  name: 'Change Password',
  components: {
    KidHeader,
    KidFooter
  },
  data() {
    return {
        profileId: this.$route.query.profileId,
        isDisabled: false,
        paymentHistoryData: [],
        showPassword: false,
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    }
  },
  methods: {
    async changePassword() {
        this.isDisabled = true;
        let data = { old_password: this.currentPassword, new_password: this.newPassword, confirm_password: this.confirmNewPassword }
      await KidsService.changePassword(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            localStorage.setItem('token', "");
            this.$router.push('login');
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    togglePasswordDisplay(showPassword) {
      if (showPassword == true) {
        $('.password-input').attr('type', 'text');
      } else {
        $('.password-input').attr('type', 'password');
      }
      this.showPassword = showPassword;
    }
  },
  beforeMount() {
  },
}
</script>