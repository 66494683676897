<template>
    <div>
        <div class="main-container">
            <KidHeader :profileId="profileId" :serialNumber="serialNumber" />
            <div class="profile-section mb-2">
                <div class="pl-2">
                    <span>
                        <a class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/kids-device', query:{ profileId: this.profileId }})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Home</b></a>
                    </span>
                </div>
            </div>
            <div class="profile-section pt-2">
                <div class="container" :style="{ 'max-height': '90%' }">
                    <div class="row">
                        <div class="col-lg-12 right">
                            <div class="box shadow-sm rounded bg-white mb-2">
                                <div class="box-body p-0">
                                    <div class="p-2 d-flex align-items-center osahan-post-header" v-for="item in tableData" :key="item._id">
                                        <div class="dropdown-list-image mr-2">
                                            <img v-if="item.playstoreDetail" class="rounded-circle" :src="item.playstoreDetail.icon" alt="" />
                                            <img v-else class="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/022/059/000/small/no-image-available-icon-vector.jpg" alt="" />
                                        </div>
                                        <div class="font-weight-bold mr-3">
                                            <div class="mb-2">
                                                <span v-if="item.playstoreDetail">{{ item.playstoreDetail.title }}</span>
                                                <span v-else>{{ item.packageName }}</span>
                                                <small v-if="item.remarks"><br>{{ item.remarks }}</small>
                                            </div>
                                            <div class="small" v-if="item.status == 'Pending'" :class="{'text-success': item.requestType === 'App Install', 'text-danger': item.requestType !== 'App Install'}">
                                                <b v-if="item.requestType == 'App Install'">App Installation Request</b>
                                                <b v-else-if="item.requestType == 'App Uninstall'">App Uninstallation Request</b>
                                                <b v-else>App Time Extend Request - {{ item.extendedTime }} Minutes.</b>
                                            </div>
                                        </div>
                                        <span class="ml-auto mb-auto" v-if="item.status == 'Pending'">
                                            <div class="text-right text-muted pt-1">
                                                <button type="button" class="btn btn-outline-success btn-sm" @click="openDailyLimitSetPopup(item.playstoreDetail, item._id, item)" :disabled="isDisabled">Approve<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>&nbsp;
                                                <button type="button" class="btn btn-outline-danger btn-sm" @click="rejectRequest(item._id)">Reject</button>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse footer" id="dailyLimitSetPopup">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="text-black">
                                            <b v-if="app">Daily Limit Configuration for {{ app.title }}</b> <a href="javascript:void(0)" class="text-black" @click="closeDailyLimitSetPopup()"><i class="fas fa-times float-right pt-1 pr-2"></i></a>
                                        </h6><hr>
                                        <p class="text-black">By setting a daily limit, you can ensure that your child is unable to use the app once the assigned time has been reached. The app will automatically be disabled once the daily limit is exceeded, promoting healthy usage habits.</p>

                                        <div class="profile-section" :style="{'border-bottom': 'unset'}">
                                            <p class="text-black"><b>Daily Limit</b> - <small class="text-black">In Minute</small></p>
                                            <div class="card w-100 background-dark">
                                                <div class="card-body pl-1" :style="{ 'padding': '3px', 'padding-top': '8px', 'padding-bottom': 'unset' }">
                                                    <span class="pl-1" :style="{ 'font-size': '15px' }">Enabled daily limit</span>
                                                    <span class="float-right" :style="{ 'padding-top': '2px' }">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="enableDailyLimit">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="pt-2 pb-4">
                                                <label class="pl-1">Set daily limit</label>
                                                <div class="float-right w-50">
                                                    <div class="input-group" :style="{ 'width': '95%', 'padding-right': '1px', 'border': '1px solid #dee2e6' }">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                                                <a class="text-black" href="javascript:void(0)" @click="decreaseLimit()">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect y="24" width="24" height="24" rx="12" transform="rotate(-90 0 24)" fill="#F2F2F2"/>
                                                                        <path d="M13.6667 15.3333L10.4003 12.157C10.311 12.0703 10.311 11.9296 10.4003 11.8429L13.6667 8.66663" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <input type="text" class="form-control text-black text-center" :style="{ 'border': 'unset', 'font-weight': 'bold' }" :disabled="enableDailyLimit==false" v-model="dailyLimitTime" @blur="roundOF15()">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                                                <a class="text-black" href="javascript:void(0)" @click="increaseLimit()">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#F2F2F2"/>
                                                                        <path d="M10.3333 8.66671L13.5997 11.843C13.689 11.9297 13.689 12.0704 13.5997 12.1571L10.3333 15.3334" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-section text-center p-3">
                                            <button type="button" class="btn btn-primary" :style=" { 'background': '#0073EE', 'border-radius': '20px', 'width': '30%' }" @click="saveAppDailyLimit()" :disabled="isDisabled">Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>&nbsp;
                                            <button type="button" class="btn btn-warning" :style=" { 'border-radius': '20px' }" @click="saveAppDailyLimit(true)" :disabled="isDisabled">Skip & Install<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse footer" id="extendDailyLimitSetPopup">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="text-black">
                                            <b v-if="app">Extend Daily Limit for {{ app.title }}</b> <a href="javascript:void(0)" class="text-black" @click="closeExtendDailyLimitSetPopup()"><i class="fas fa-times float-right pt-1 pr-2"></i></a>
                                        </h6><hr>
                                        <p class="text-black">Your child has requested an extension to their daily app usage limit. As a parent, you can review and approve this request to allow additional time.</p>

                                        <div class="profile-section" :style="{'border-bottom': 'unset'}">
                                            <div class="pt-2 pb-4">
                                                <p class="pl-1">The current daily limit is set to <span class="text-danger"><b>{{ currentDailyLimit }} minutes</b></span>. Once this request is approved, the limit will be updated accordingly.</p>
                                                <label class="pl-1">Extend app daily limit</label>
                                                <div class="float-right w-50">
                                                    <div class="input-group" :style="{ 'width': '95%', 'padding-right': '1px', 'border': '1px solid #dee2e6' }">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                                                <a class="text-black" href="javascript:void(0)" @click="decreaseLimit()">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect y="24" width="24" height="24" rx="12" transform="rotate(-90 0 24)" fill="#F2F2F2"/>
                                                                        <path d="M13.6667 15.3333L10.4003 12.157C10.311 12.0703 10.311 11.9296 10.4003 11.8429L13.6667 8.66663" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <input type="text" class="form-control text-black text-center" :style="{ 'border': 'unset', 'font-weight': 'bold' }" :disabled="enableDailyLimit==false" v-model="dailyLimitTime" @blur="roundOF15()">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                                                <a class="text-black" href="javascript:void(0)" @click="increaseLimit()">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#F2F2F2"/>
                                                                        <path d="M10.3333 8.66671L13.5997 11.843C13.689 11.9297 13.689 12.0704 13.5997 12.1571L10.3333 15.3334" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="text-right">New daily limit is <span class="text-danger"><b>{{ currentDailyLimit + dailyLimitTime }} minutes</b></span>.</p>
                                        </div>
                                        <div class="alert alert-danger" role="alert" v-if="categoryId">
                                            The application category is currently disabled. To extend the app's daily limit, please enable the category and check its daily limit settings. 
                                            <a href="javascript:void(0)" @click="$router.push({ path: '/category', query: { id: categoryId, profileId: profileId, serialNumber: serialNumber, appRequestPage: true }})">Click here</a> 
                                            to go to the category page.
                                        </div>
                                        <div class="profile-section text-center p-3">
                                            <button type="button" class="btn btn-primary" :style=" { 'background': '#0073EE', 'border-radius': '20px', 'width': '30%' }" @click="approveExtendDailyLimitRequest()" :disabled="isDisabled">Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="tableData.length == 0">No data found.</p>
            </div>
            <KidFooter />
        </div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import KidFooter from '@/components/KidFooter.vue'
import KidsService from "../../services/KidsService";
import ProfileService from "../../services/ProfileService";

export default {
  name: 'App Requests',
  components: {
    KidHeader,
    KidFooter
  },
  data() {
    return {
        isDisabled: false,
        profileId: this.$route.query.profileId,
        serialNumber: this.$route.query.serialNumber,
        tableData: [],
        enableDailyLimit: true,
        dailyLimitTime: 60,
        currentDailyLimit: 0,
        app: null,
        requestId: "",
        item: null,
        categoryId: null,
    }
  },
  methods: {
    async getAppRequestList() {
        let data = { profileId: this.profileId, serialNumber: this.serialNumber, status: "Pending" };
        await KidsService.getAppRequestList(data)
        .then((response) => {
          this.tableData = response.data.data;
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async rejectRequest(id) {
        let data = { profileId: this.profileId, serialNumber: this.serialNumber, id: id };
        await KidsService.rejectRequest(data)
        .then((response) => {
            this.getAppRequestList();
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async approveRequest(app, requestId, item, skipDailyConfiguration = false) {
        if (!app) {
            return this.$toast.error("Playstore detail not found for this app, please try again after sometime.");
        }
        if (item.requestType == "App Uninstall") {
            this.isDisabled = true;
            let data = {
                package_name: item.packageName,
                profile_id: this.profileId,
                serialNumber: this.serialNumber,
                requestId: requestId
            };

            await ProfileService.uninstallProfileApplication(data)
                .then(async (response) => {
                    this.applyPolicy();
                    this.getAppRequestList();
                    this.deleteProfileApplication(item.packageName);
                    this.isDisabled = false;
                    this.$toast.success(response.data.respones_msg);
                }).catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
                });
        } else {
            this.isDisabled = true;
            let masterApplicationData = {
                product_id: app.appId,
                package_name: app.appId,
                app_icon: app.icon,
                application_details: app,
                type: "Android"
            };
            await ProfileService.createMasterApplication(masterApplicationData)
            .then((response) => {
            }).catch((e) => {
            })

            let data = {
                profile_id: this.profileId,
                package_name: app.appId,
                is_web_app: false,
                type: "Android",
                serialNumber: this.serialNumber,
                requestId: requestId,
                enableDailyLimit: (skipDailyConfiguration == true) ? false : this.enableDailyLimit,
                dailyLimitTime: (skipDailyConfiguration == true) ? 0 : this.dailyLimitTime
            };

            await ProfileService.createProfileApplication(data)
            .then((response) => {
                this.applyPolicy();
                this.isDisabled = false;
                this.$toast.success(response.data.respones_msg);
                this.enableDailyLimit = true;
                this.dailyLimitTime = 60;
                $('#dailyLimitSetPopup').collapse('hide')
                this.getAppRequestList();
            }).catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });
        }
    },
    deleteProfileApplication(package_name) {
        this.isDisabled = true;
        var data = {
          package_name: package_name,
          profile_id: this.profileId,
          serialNumber: this.serialNumber
        };

        ProfileService.deleteProfileApplication(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async applyPolicy() {
      this.isDisabled = true;
      let data = {
        profile_id: this.profileId,
        serialNumber: this.serialNumber
      };

      await ProfileService.applyPolicy(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          //this.$toast.error(e.response.data.respones_msg);
        });
    },
    async openDailyLimitSetPopup(app, requestId, item) {
        if (item.requestType == "App Uninstall") {
            return await this.approveRequest(app, requestId, item);
        }
        this.app = app,
        this.requestId = requestId,
        this.item = item;
        if (item.requestType == "Extend Time") {
            this.dailyLimitTime = item.extendedTime;
            this.currentDailyLimit = (item.profileAppDetail.dailyLimitTime) ? parseInt((item.profileAppDetail.dailyLimitTime)) : 0
            $('#extendDailyLimitSetPopup').collapse('show');
        } else {
            $('#dailyLimitSetPopup').collapse('show')
        }
    },
    async approveExtendDailyLimitRequest() {
        let data = { id: this.item._id, extendLimit: this.dailyLimitTime };
        await KidsService.approveExtendDailyLimitRequest(data)
        .then((response) => {
            this.applyPolicy();
            this.categoryId = null,
            this.currentDailyLimit = 0;
            this.dailyLimitTime = 0;
            $('#extendDailyLimitSetPopup').collapse('hide');
            this.getAppRequestList();
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            if (e.response.data.redirectToCategory == true) {
                this.categoryId = e.response.data.data
            } else {
                this.$toast.error(e.response.data.respones_msg);   
            }
        });
    },
    async saveAppDailyLimit(skipDailyConfiguration) {
        return await this.approveRequest(this.app, this.requestId, this.item, skipDailyConfiguration);
    },
    closeDailyLimitSetPopup(){
        $('#dailyLimitSetPopup').collapse('hide')
    },
    closeExtendDailyLimitSetPopup(){
        $('#extendDailyLimitSetPopup').collapse('hide')
    },
    decreaseLimit() {
        if (this.dailyLimitTime > 0) {
            this.dailyLimitTime = this.dailyLimitTime - 15
        }
    },
    increaseLimit() {
        this.dailyLimitTime = (parseInt(this.dailyLimitTime) + 15)
    },
    roundOF15() {
        this.dailyLimitTime = Math.ceil(this.dailyLimitTime/15.0) * 15;
    },
  },
  watch: {
    enableDailyLimit() {
        if (this.enableDailyLimit == false) {
          this.dailyLimitTime = 0
        } else {
            this.dailyLimitTime = 15
        }
    },
    dailyLimitTime() {
        if (this.dailyLimitTime < 0) {
            this.dailyLimitTime = 0;
        }
    },
  },
  beforeMount() {
    this.getAppRequestList();
  },
}
</script>