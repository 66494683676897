import axios from "axios";
axios.defaults.withCredentials = true;
const headers = { "Content-Type": "application/json" };
if (localStorage.getItem('token')) {
  headers.Authorization = localStorage.getItem('token');
}
export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: headers
});