import http from "../http-common";

class KidsService {
    getKidsList(data) {
        return http.get("device/kids-list", { params: data });
    }
    getPlayStore(data) {
        return http.get("application/playstore", { params: data });
    }
    getDeviceProfileMostUsedApplicationList(data) {
        return http.get("device/most-used-apps", { params: data });
    }
    getMostUsedCategory(data) {
        return http.get("application/most-used-category", { params: data });
    }
    getCategoryDetail(data) {
        return http.get("application/category-detail", { params: data });
    }
    updateCategoryDailyLimit(data) {
        return http.post("application/category/limit/update", data);
    }
    getCategoryStackedChart(data) {
        return http.get("application/category-chart", { params: data });
    }
    getCategoryDetailStackedChart(data) {
        return http.get("application/category-detail-chart", { params: data });
    }
    getAppUsageChart(data) {
        return http.get("application/usage-chart", { params: data });
    }
    getDeviceDetailBySerialNumber(data) {
        return http.get("device/by-serialNumber", { params: data });
    }
    submitParentRating(data) {
        return http.post("application/parent/rating", data);
    }
    updateAppRecommendations(data) {
        return http.post("application/parent/app/recommended", data);
    }

    getNotifications(data) {
        return http.get("notification/kid/list", { params: data });
    }
    unReadNotificationCount(data) {
        return http.get("notification/kid/unread/count", { params: data });
    }
    toggleSleepMode(data) {
        return http.post("device-profile/update/sleepMode", data);
    }
    getPendingRatingApps(data) {
        return http.get("application/pending/rating", { params: data });
    }
    markAsShownAppRating(data) {
        return http.get("application/pending/rating/mark-as-shown", { params: data });
    }
    getPaymentHistory() {
        return http.get("payment/history");
    }
    getPaymentMethods() {
        return http.get("payment/list/payment-methods");
    }
    markAsDefaultCard(data) {
        return http.post("payment/card/change/default/card", data);
    }
    deletePaymentMethod(data) {
        return http.post("payment/card/delete", data);
    }
    submitPaymentMethod(data) {
        return http.post("payment/card/create", data);
    }
    changePassword(data) {
        return http.post("users/change-password", data);
    }
    getBrowserLogs(data) {
        return http.get("browser-logs/list", { params: data });
    }
    updateLogUrlStatus(data) {
        return http.post("browser-logs/update/status", data);
    }
    getAppRequestList(data) {
        return http.get("application/request/list", { params: data });
    }
    rejectRequest(data) {
        return http.post("application/request/reject", data);
    }
    approveExtendDailyLimitRequest(data) {
        return http.post("application/request/approve", data);
    }

    updateKidsProtectionStatus(data) {
        return http.post("device-profile/update/protectionStatus", data);
    }
}

export default new KidsService();