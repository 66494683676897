<template>
    <div>
        <div class="main-container">
            <KidHeader :profileId="profileId" :serialNumber="serialNumber" />
            <div class="profile-section mb-2">
                <div class="pl-2">
                    <span>
                        <a class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/kids-device', query:{ profileId: this.profileId }})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Home</b></a>
                    </span>
                </div>
            </div>
            <div class="collapse footer" id="addPaymentMethodPopup">
                <div class="card">
                    <div class="card-body">
                        <h6 class="text-black">
                            <b>Add Payment Methods</b> <a href="javascript:void(0)" class="text-black" @click="closeAddPaymentMethodPopup()"><i class="fas fa-times float-right pt-1 pr-2"></i></a>
                        </h6><hr>
                        <form>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-3 col-form-label">Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="cardHolderName" >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputPassword" class="col-sm-3 col-form-label">Card Number</label>
                                <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="cardNumber">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" v-model="expiryMonth" placeholder="Expiry Month">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" v-model="expiryYear" placeholder="Expiry Year">
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col">
                                    <input type="text" class="form-control" v-model="cvc" placeholder="CVC">
                                </div>
                            </div>
                            <div class="pt-2">
                                <button type="button" class="btn btn-primary w-100" :disabled="isDisabled" :style="{ 'border-radius': '20px' }" @click="submitPaymentMethod()">
                                    Submit<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="profile-section pt-2 m-2">
                <div> <b>Payment Methods</b> 
                    <span class="float-right pr-2">
                        <button class="btn btn-dark" :style="{ background: '#343a40' }" @click="openAddPaymentMethodPopup()">Add</button>
                    </span>
                </div><br>
                <div class="card shadow-sm mb-2" v-for="pm in paymentMethods.data" :key="pm.id">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <h5 class="ml-2 mb-0 font-weight-bold">{{ toSentenceCase(pm.card.brand) }} ending in {{ pm.card.last4 }}</h5>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{ pm.card.exp_month }}/{{ pm.card.exp_year }}</span>
                                <i v-if="pm.id == paymentMethods.default_source" class="fa fa-star ml-2"></i>
                            </div>
                        </div>
                        <div class="billing-box mt-3">
                            <strong>Billing Details:</strong>
                            <p class="mb-0" v-if="pm.billing_details"><b>Name:</b> {{ pm.billing_details.name }}</p>
                            <p class="mb-0" v-if="pm.billing_details"><b>Email:</b> {{ pm.billing_details.email }}</p>
                            <p class="mb-0" v-if="pm.billing_details && pm.billing_details.address">
                                <b>Address:</b>{{ pm.billing_details.address['line1'] }} {{ pm.billing_details.address['line2'] }}
                                {{ pm.billing_details.address.city }}, {{ pm.billing_details.address.postal_code }}, {{ pm.billing_details.address.country }}
                            </p>
                        </div>
                        <div class="btn-group">
                            <button v-if="pm.id != paymentMethods.default_source" class="btn btn-outline-danger mr-1" :style="{ background: '#dc3545', color: 'white' }" @click="deletePaymentMethod(pm.id)" :disabled="isDisabled">Delete</button>
                            <button v-if="pm.id != paymentMethods.default_source" class="btn btn-dark mr-1" :style="{ background: '#343a40' }" @click="markAsDefaultCard(pm.id)" :disabled="isDisabled">Mark As Default</button>
                        </div>
                    </div>
                </div> 
            </div>
            <KidFooter />
        </div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import KidFooter from '@/components/KidFooter.vue'
import KidsService from "../../services/KidsService";

export default {
  name: 'Payment History',
  components: {
    KidHeader,
    KidFooter
  },
  data() {
    return {
        profileId: this.$route.query.profileId,
        isDisabled: false,
        paymentMethods: [],
        cardHolderName: "",
        cardNumber:"",
        expiryMonth: "",
        expiryYear: "",
        cvc: ""
    }
  },
  methods: {
    async getPaymentMethods() {
      await KidsService.getPaymentMethods()
        .then((response) => {
          this.paymentMethods = response.data.data;
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    toSentenceCase(value) {
      if (!value) return value;
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    async markAsDefaultCard(id) {
        this.isDisabled = true;
        let data = { id: id, isKids: true }
        await KidsService.markAsDefaultCard(data)
        .then((response) => {
            this.isDisabled = false;
          this.getPaymentMethods();
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async deletePaymentMethod(id) {
        this.isDisabled = true;
        let data = { id: id, isKids: true }
        await KidsService.deletePaymentMethod(data)
        .then((response) => {
            this.isDisabled = false;
            this.getPaymentMethods();
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    async submitPaymentMethod() {
        this.isDisabled = true;
        let data = { card_name: this.cardHolderName, number: this.cardNumber, exp_month: this.expiryMonth,
        exp_year: this.expiryYear, cvc: this.cvc, isKids: true }
        await KidsService.submitPaymentMethod(data)
        .then((response) => {
            this.isDisabled = false;
            $('#addPaymentMethodPopup').collapse('hide')
            this.getPaymentMethods();
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    openAddPaymentMethodPopup() {
        $('#addPaymentMethodPopup').collapse('show')
    },
    closeAddPaymentMethodPopup() {
        $('#addPaymentMethodPopup').collapse('hide')
    },
  },
  beforeMount() {
    this.getPaymentMethods();
  },
}
</script>